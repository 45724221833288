@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800;900&display=swap');
a{
    color: none;
    text-decoration: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
  }
/* Navbar CSS*/
.Navbar{
    position: sticky;
    top: 0;
    background: #f1f1f1;
    /* padding-bottom: 10px; */
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
  }
  .Navbar-LogoName {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
  
  .Navbar-LogoName img {
    height: 2.5rem;
  }
  
  .Navbar-LogoName a {
    font-family: 'Lexend', sans-serif;
    font-weight: 700;
    font-size: 2.4rem;
    margin-left: 10px;
    line-height: 86px;
    color: #47BC9D;
    text-decoration: none;
    color: #47BC9D;
  }
  nav{
    display: flex;
    align-items: center;
  }
  .SearchBox{
    border: 1px solid #000;
    border-radius: 10px;
    padding: 5px 10px;
    width: 60vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left:40px;
  }
  .SearchBox img{
    height: 1.5rem;
  }
  .SearchBox input{
    border: none;
    width: 100%;
    background: transparent;
  }
  .SearchBox input:focus-visible{
    outline: none;
  }
  .loginSignUp{
    margin-right: 20px;
    color: #000;
    font-weight: 600;
  }
  /* Cards CSS */
.Cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 10px;
    padding: 5px 10px ;
    color: black;
}

.ImgContainer img {
    aspect-ratio: 16 / 9;
    overflow: hidden;
    width: 100%;
    border-radius: 10px;
}

.descContainer {
    width: 100%;
}
.descContainer .CardName {
    text-align: start;
    font-size: 1.5rem;
    font-weight: 600;
}

.descContainer .Desc {
    text-align: start;
    overflow: hidden;
    width: 100%;
    white-space: normal;
    text-overflow: ellipsis;
}
.descContainer .tagLine{
    text-align: start;
    border: 1px dashed rgb(9, 207, 138);
}

/* Gift Card Page */
.MainCotainer{
    margin: 0;

}
.CardsDeatails{
    overflow: hidden;
    background: #7ef1d2fa;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100vw;
}
.CardsDeatails :nth-child(2){
    overflow: hidden;
}
.CardsDeatails :nth-child(2) img{
    border-radius: 10px;
    width: 100%;
}
.CardsDeatails :nth-child(3) .InputContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
}
.CardsDeatails :nth-child(3) .InputContainer input{
    border: none;
    border-bottom: 1px solid #000;
    background: #47bc9d00;
}
.CardsDeatails :nth-child(3) .InputContainer input:focus-visible{
    outline: none;
}
.CardsDeatails :nth-child(3) .BuyingOption{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.CardsDeatails :nth-child(3) .BuyingOption label{
    margin: 0 10px;
}
.CheckBox{
    padding: 0 5px;
}
.DeliveryOptions{
    display: flex;
    align-items: start;
}
.DeliveryOptions .CheckBox label{
    margin-left:10px ;
}
.Details{
    width: 100vw;
}
.Details div{
    width: 25vw;
    margin-left: 20px;
    padding: 20px 0;
}
.Details div input{
    border: none;
    width: 100%;
    border-bottom: 1px solid#000;
}
.Details div input:focus-visible{
    outline: none;
}
.Details{
    padding: 20px 0;
}
.PayNow button{
    background: #47BC9D;
    border: none;
    width: fit-content;
    border-radius: 20px;
    margin:  0 auto;
    font-size: 2rem;
    font-weight: 700;
    color: #ffffff;
}
.categoryContainer{
    background: #e3e3e3;
    color: #000;
}
.categoryContainer div{
    margin-top: auto;
    margin-bottom: auto;
}
.DropdownContainer{
    /* position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    /* z-index: 1024; */
    background: #e3e3e3;
    backdrop-filter: blur(10px);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:  ease-in-out 1s;
}
.CategoryDropdown{
    display: flex;
    background: #e3e3e3;
}
.CategoryDropdown button{
    border: none;
    background: transparent;
    margin-left: 20px;
    padding: 10px 0;
}
.categoryList ul{
    list-style: none;
}
.PaymentSection{
    padding: 10px 20px ;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1024;
    background: #ffffff3e;
}
.PaymentSection div{
    background: #fff;
    padding: 20px 30px;
    border-radius: 20px;
}
.PaymentSection input{
    border: none;
    border-bottom:1px solid #000;
    margin-left: 20px;
}
.PaymentSection input:focus-visible{
    outline: none;
}
.PaymentSection button{
    border: none;
    background: transparent;
}
.CardImage{
    width: 40vw;
    height: 30vh;
    object-fit: fill;
}